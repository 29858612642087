import axios from 'axios';
import {
  Consultation,
  ConsultationMatch,
  EnrichedExpert,
  Experience,
  Expert,
  Expertise,
  User
} from "./entities/Entities";
import {FormConsultation} from "./entities/FormEntities";

export interface DirectusWrapper<T> {
  data: T
}

export enum Entity {
  User = "user",
  Consultation = "consultation",
  Expert = "expert",
  Expertise = "expertise",
  Experience = "experience",
  ConsultationMatch = "consultation_match",
}

const directusConfig: DirectusConfig = {
  token: "token",
  host: "https://profound.directus.app/items",
};

interface DirectusConfig {
  token: string,
  host: string
}

class DirectusClient {
  config: DirectusConfig;

  constructor(cfg: DirectusConfig) {
    this.config = cfg;
  }

  buildHeaders() {
    return {'Authorization': `Bearer ${this.config.token}`};
  }

  buildRequestConfig() {
    return {
      headers: this.buildHeaders(),
      baseURL: this.config.host
    };
  }



  async doGetMany<T>(path, params = ""): Promise<DirectusWrapper<T[]>> {
    try {
      return (await axios.get(`/${path}/${params}`, this.buildRequestConfig())).data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async doGetOne<T>(path, id, params = ""): Promise<DirectusWrapper<T>> {
    try {
      return (await axios.get(`/${path}/${id}/${params}`, this.buildRequestConfig())).data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async deleteOne<T>(path, id): Promise<DirectusWrapper<T>> {
    try {
      return (await axios.delete(`/${path}/${id}`, this.buildRequestConfig()));
    } catch (err) {
      console.error(err);
      throw err;
    }
  }


  async doPost(path, data) {
    try {
      console.log(path, data, this.buildHeaders())
      return (await axios.post(`/${path}`, data, this.buildRequestConfig())).data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  async doPatch(path, id, data) {
    try {
      return (await axios.patch(`/${path}/${id}`, data, this.buildRequestConfig())).data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };


  async getUserByAuthId(id: string): Promise<User> {
    try {
      const encodedId = encodeURIComponent(id)
      const user = await this.doGetMany<User>(Entity.User, `?filter[authUUID][_eq]=${encodedId}`)
      console.log(user);
      return user.data[0];
    } catch (err) {
      console.error(err);
      throw err;
    }
  };


  async getConsultations(): Promise<DirectusWrapper<Consultation[]>> {
    try {
      const records = await this.doGetMany<Consultation>(Entity.Consultation)
      return records;
    } catch (err) {
      return Promise.reject();
    }
  };

  async getConsultationsByUser(userId: number): Promise<DirectusWrapper<Consultation[]>> {
    try {
      const records = await this.doGetMany<Consultation>(Entity.Consultation, `?filter[userId][_eq]=${userId}`)
      return records;
    } catch (err) {
      return Promise.reject();
    }
  };


  async getEnrichedExperts(ids: string[]): Promise<Awaited<DirectusWrapper<EnrichedExpert>>[]> {
    const promises: Promise<DirectusWrapper<EnrichedExpert>>[] = ids.map(_ => this.getEnrichedExpert(_));
    const experts = Promise.all(promises);
    return experts;
  }

  async getEnrichedExpert(id: string): Promise<DirectusWrapper<EnrichedExpert>> {
    try {
      const expert = await this.doGetOne<Expert>(Entity.Expert, id, "?fields=*,userId.*")
      const expertise = await this.doGetMany<Expertise>(Entity.Expertise, `?filter[expertId][_eq]=${id}`)
      const experience = await this.doGetMany<Experience>(Entity.Experience, `?filter[expertId][_eq]=${id}`)
      const enrichedExpert: EnrichedExpert = {...expert.data, expertise: expertise.data, experience: experience.data}
      console.debug(enrichedExpert);
      return {data: enrichedExpert};
    } catch (err) {
      return Promise.reject();
    }
  };

  async getConsultationById(id: string): Promise<DirectusWrapper<Consultation>> {
    try {
      const record = await this.doGetOne<Consultation>(Entity.Consultation, id)
      return record;
    } catch (err) {
      return Promise.reject();
    }
  };

  async getEnrichedExpertsByConsultationId(consultationId): Promise<Awaited<DirectusWrapper<EnrichedExpert>>[]> {
    try {
      const records: ConsultationMatch[] = (await this.doGetMany<ConsultationMatch>(Entity.ConsultationMatch,
        `?filter[consultationId][_eq]=${consultationId}`)).data
      return this.getEnrichedExperts(records.map(_=>_.expertId));
    } catch (err) {
      return Promise.reject();
    }
  };

  async saveUser(user) {
    try {
      console.debug("creating user", user);
      const response = await this.doPost(Entity.User, user)
      return response;
    } catch (err) {
      throw err;
    }
  };


  async createConsultation(user: User, formConsultation: FormConsultation) {
    try {
      console.debug("Saving inquiry", user, formConsultation)
      await this.doPost(Entity.Consultation, this.buildConsultation(user.id, formConsultation))
    } catch (err) {
      throw err;
    }
  };

  async updateConsultation(user: User, consultationId: string, formConsultation: FormConsultation) {
    try {
      console.debug("Update consultation", user, formConsultation)
      await this.doPatch(Entity.Consultation, consultationId, this.buildConsultation(user.id, formConsultation))
    } catch (err) {
      throw err;
    }
  };

  async updateUser(user: User) {
    try {
      console.debug("Update user", user)
      return await this.doPatch(Entity.User, user.id, user)
    } catch (err) {
      throw err;
    }
  };


  buildConsultation(userId: number, formConsultation: FormConsultation): Consultation {
    return {
      title: formConsultation.title,
      niceToHave: formConsultation.niceToHave,
      mustHave: formConsultation.mustHave,
      questions: formConsultation.questions,
      urgency: formConsultation.urgency,
      preferredLocation: formConsultation.preferredLocation,
      problemStatement: formConsultation.problemStatement,
      userId: userId,
      hourlyRate: formConsultation.hourlyRate,
      hours: formConsultation.hours,
      status: "Active"
    }
  }
}

const directusClient = new DirectusClient(directusConfig);
export {
  directusClient,
};


import {createSlice} from '@reduxjs/toolkit';
import {SERVICE_URL} from 'config.js';
import axios from 'axios';
import {directusClient} from "../../../backend/DirectusClient";
import {Consultation} from "../../../backend/entities/Entities";


const initialState = {
  consultations: [],
  loading: false,
  showSettings: {folder: 'All', starred: 'All', important: 'All', tags: 'All'},
  selectedItems: [],
  viewingConsultation: null,
};

const consultationSlice = createSlice({
  name: 'consultations',
  initialState,
  reducers: {
    receiveService(state, action) {
      const {consultations, loading} = action.payload;
      state.consultations = consultations
      state.loading = loading;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    toggleSelectConsultation(state, action) {
      const item = action.payload;
      if (state.selectedItems.find((x) => x.id === item.id)) {
        state.selectedItems = state.selectedItems.filter((x) => x.id !== item.id);
      } else {
        state.selectedItems = [item, ...state.selectedItems];
      }
    },
    setSelectedConsultations(state, action) {
      state.selectedItems = action.payload;
    },
    setViewingConsultation(state, action) {
      state.viewingConsultation = action.payload;
    },
    setShowSettings(state, action) {
      state.showSettings = action.payload;
    },
  },
});
const {setLoading, receiveService} = consultationSlice.actions;

export const {toggleSelectConsultation, setSelectedConsultations, setViewingConsultation, setShowSettings} = consultationSlice.actions;

export const getConsultations = () => async (dispatch) => {
  dispatch(setLoading(true));
  const consultations: Consultation[] = (await directusClient.getConsultations()).data;
  dispatch(receiveService({consultations, loading: false}));
};

export const updateConsultation =
  ({item}) =>
    async (dispatch) => {
      dispatch(setLoading(true));
      const response = await axios.put(`${SERVICE_URL}/apps/mailbox`, {item});
      const consultations = response.data;
      dispatch(receiveService({consultations, loading: false}));
    };
export const deleteConsultation =
  ({ids}) =>
    async (dispatch) => {
      const response = await axios.delete(`${SERVICE_URL}/apps/mailbox`, {params: ids});
      const consultations = response.data;
      dispatch(receiveService({consultations, loading: false}));
    };

const consultationsReducer = consultationSlice.reducer;

export default consultationsReducer;

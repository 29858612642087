import {createSlice} from '@reduxjs/toolkit';
import {User} from "../backend/entities/Entities";
import {directusClient} from "../backend/DirectusClient";
import {USER_ROLE} from "../constants";

const CryptoJS = require("crypto-js");

export interface AuthSliceState {
  isLogin: boolean
  currentUser?: User
}

const user = JSON.parse(localStorage.getItem('user'));


if (user) {
  // Intercom Setup for email and user data
  const hmac = CryptoJS.HmacSHA256(user.authUUID, "b85_jRtMwu9V1KJHhSeafWacZcdZdDUJ8PBW6Btp");
  const hash = hmac.toString();
  // @ts-ignore
  window.intercomSettings = {
    api_base: "https://api-iam.intercom.io",
    app_id: "d8bxm62j",
    user_id: user.authUUID, // User ID
    user_hash: hash // HMAC using SHA-256
  };
  // @ts-ignore
  window.dataLayer.push({user_email: user.email}, {user_id: user.authUUID});
}

const initialState: AuthSliceState = {
  isLogin: user != null,
  currentUser: user ? user : {},
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser(state, action) {
      console.log("setCurrentUser", action);
      const user = action.payload;
      localStorage.setItem("user", JSON.stringify(user));
      state.currentUser = user !== null ? user : {};
      state.isLogin = user ? user.status === "ACTIVE" : false;
    },
  },
});

export const signInOrCreateUser =
  (authUser) =>
    async (dispatch) => {
      if (authUser) {
        let user = await directusClient.getUserByAuthId(authUser.uid)
        if (!user) {
          user = (await directusClient.saveUser(defaultUserState(authUser))).data;
        }
        dispatch(setCurrentUser(user));
      }
    };

export const updateCurrentUser =
  (currentUser) =>
    async (dispatch) => {
      console.log("saveCurrentUser")
      if (currentUser) {
        currentUser = (await directusClient.updateUser(currentUser)).data;
        dispatch(setCurrentUser(currentUser));
      }
    };

const defaultUserState = (firebaseUser): User => {
  return {
    authUUID: firebaseUser.uid,
    name: firebaseUser.displayName ? firebaseUser.displayName : firebaseUser.email,
    avatar: firebaseUser.photoURL || "https://firebasestorage.googleapis.com/v0/b/hihere-web.appspot.com/o/user_logo.png?alt=media&token=43d30d7f-65d6-4d72-8e58-ec3752260c90",
    role: USER_ROLE.Customer,
    email: firebaseUser.email,
    status: "ACTIVE",
    username: firebaseUser.uid,
    stripeAccountId: "",
    stripeAccountStatus: false,
    location: ""
  };
}

export const {setCurrentUser} = authSlice.actions;
const authReducer = authSlice.reducer;

export default authReducer;

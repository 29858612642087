/* eslint-disable */
import {lazy} from 'react';
import {USER_ROLE} from 'constants.js';
import {DEFAULT_PATHS} from 'config.js';

const apps = {
  consultations: lazy(() => import('views/apps/consultations/Consultations')),
};
const pages = {
  consultation: {
    edit: lazy(() => import('views/apps/consultations/ConsultationForm')),
    detail: lazy(() => import('views/apps/consultations/ConsultationDetail')),
  },
  authentication: {
    index: lazy(() => import('views/pages/authentication/Authentication')),
    login: lazy(() => import('views/pages/authentication/Login')),
    register: lazy(() => import('views/pages/authentication/Register')),
    forgotPassword: lazy(() => import('views/pages/authentication/ForgotPassword')),
    resetPassword: lazy(() => import('views/pages/authentication/ResetPassword')),
  },
  profile: {
    settings: lazy(() => import('views/pages/profile/ProfileSettings')),
  },
  expert: {
    profile: lazy(() => import('views/pages/expert/ExpertProfile')),
  }
};

const appRoot = DEFAULT_PATHS.APP.endsWith('/') ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length) : DEFAULT_PATHS.APP;

const routesAndMenuItems = {
  mainMenuItems: [
    {
      path: DEFAULT_PATHS.APP,
      exact: true,
      redirect: true,
      to: `${appRoot}/profound/consultation/list`,
    },
    {
      path: DEFAULT_PATHS.PROFOUND,
      roles: [USER_ROLE.Admin, USER_ROLE.Customer, USER_ROLE.Expert],
      label: 'menu.consultations',
      subs: [
        {path: '/consultation/new', label: 'menu.consultations.new', component: pages.consultation.edit},
        {path: '/consultation/edit', hideInMenu: true, component: pages.consultation.edit},
        {path: '/consultation/list', label: 'menu.consultations.list', component: apps.consultations},
        {path: '/consultation/', hideInMenu: true, component: pages.consultation.detail},
      ],
    },
    {
      path: DEFAULT_PATHS.PROFOUND,
      roles: [USER_ROLE.Admin, USER_ROLE.Expert, USER_ROLE.Customer],
      label: 'menu.experts',
      hideInMenu: true,
      subs: [
        {path: '/expert/profile', label: 'menu.experts.browse', component: pages.expert.profile},
        {path: '/expert/expertform', label: 'menu.experts.signup', component: pages.profile.expertForm}
      ],
    },
    {
      path: `${DEFAULT_PATHS.PROFOUND}/profile`,
      roles: [USER_ROLE.Admin, USER_ROLE.Customer, USER_ROLE.Expert],
      component: pages.profile.settings,
      hideInMenu: true,
    },
    {
      path: `${appRoot}/pages`,
      roles: [USER_ROLE.Admin],
      label: 'menu.pages',
      icon: 'notebook-1',
      component: pages.index,
      subs: [
        {
          path: '/authentication',
          label: 'menu.authentication',
          component: pages.authentication.index,
          subs: [
            {path: '/login', label: 'menu.login', component: pages.authentication.login, noLayout: true},
            {path: '/register', label: 'menu.register', component: pages.authentication.register, noLayout: true},
            {
              path: '/forgot-password',
              label: 'menu.forgot-password',
              component: pages.authentication.forgotPassword,
              noLayout: true
            },
            {
              path: '/reset-password',
              label: 'menu.reset-password',
              component: pages.authentication.resetPassword,
              noLayout: true
            },
          ],
        },
        {
          path: '/profile',
          label: 'menu.profile',
          component: pages.profile.index,
          subs: [
            {path: '/standard', label: 'menu.standard', component: pages.profile.standard},
            {path: '/settings', label: 'menu.settings', component: pages.profile.settings},
          ],
        },
      ],
    },
  ],
  sidebarItems: [
    {path: '#connections', label: 'menu.connections', icon: 'diagram-1', hideInRoute: true},
    {path: '#bookmarks', label: 'menu.bookmarks', icon: 'bookmark', hideInRoute: true},
    {path: '#requests', label: 'menu.requests', icon: 'diagram-2', hideInRoute: true},
    {
      path: '#account',
      label: 'menu.account',
      icon: 'user',
      hideInRoute: true,
      subs: [
        {path: '/settings', label: 'menu.settings', icon: 'gear', hideInRoute: true},
        {path: '/password', label: 'menu.password', icon: 'lock-off', hideInRoute: true},
        {path: '/devices', label: 'menu.devices', icon: 'mobile', hideInRoute: true},
      ],
    },
    {
      path: '#notifications',
      label: 'menu.notifications',
      icon: 'notification',
      hideInRoute: true,
      subs: [
        {path: '/email', label: 'menu.email', icon: 'email', hideInRoute: true},
        {path: '/sms', label: 'menu.sms', icon: 'message', hideInRoute: true},
      ],
    },
    {
      path: '#downloads',
      label: 'menu.downloads',
      icon: 'download',
      hideInRoute: true,
      subs: [
        {path: '/documents', label: 'menu.documents', icon: 'file-text', hideInRoute: true},
        {path: '/images', label: 'menu.images', icon: 'file-image', hideInRoute: true},
        {path: '/videos', label: 'menu.videos', icon: 'file-video', hideInRoute: true},
      ],
    },
  ],
};
export default routesAndMenuItems;
